import axios from "axios";
import { Link, navigate } from "gatsby";
import React, { useState, useEffect } from "react";
import '../styles/updateSettings.scss';

const UpdateSettings = () => {
    const [name, setName] = useState("");
    const [handle, setHandle] = useState("");
    const [email, setEmail] = useState("");
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tokenExists, setTokenExists] = useState(false);


    const handleDelete = () => {
        if (!localStorage.getItem('token')) {
            navigate('/signup');
        } else {
            setDeleteLoading(true);
            const { token, settings } = JSON.parse(localStorage.getItem('token'));
            const handle = name.toLowerCase().replace(/\s/g, ".");
            axios.post('https://api.opzoom.com/access4/test/deleteUser', {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            }).then(res => {
                setDeleteLoading(false);
                localStorage.clear();
                navigate("/")
            }).catch(err => {
                console.log("Error: ", err)
                setDeleteLoading(false);
            })
        }
    };

    const handleLogout = () => {
        localStorage.clear();
        navigate("/")
    }

    const handleSubmit = (e) => {
        if (!localStorage.getItem('token')) {
            navigate('/signup');
        } else {
            const { token, settings } = JSON.parse(localStorage.getItem('token'));
            const handle = name.toLowerCase().replace(/\s/g, ".");
            e.preventDefault();
            setLoading(true);
            axios.post('https://api.opzoom.com/access4/test/updateSettings', {
                settings: {
                    phone: settings.phone,
                    name,
                    handle,
                    email
                },
                tags: []
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            }).then(res => {
                localStorage.setItem('update', res.data.settings.name);
                const { token } = JSON.parse(localStorage.getItem("token"));
                console.log("Success: ", res.data)
                localStorage.setItem("token", JSON.stringify({
                    settings: res.data.settings,
                    token
                }))
                setLoading(false);
                const redirect = JSON.parse(sessionStorage.getItem("redirect"));
                if (redirect && redirect.pathname) {
                    const newRedirect = { ...redirect }
                    delete newRedirect["pathname"]
                    sessionStorage.setItem("redirect", JSON.stringify(newRedirect))
                    navigate(redirect.pathname)
                }
            }).catch(err => {
                console.log("Error: ", err)
                setLoading(false);
            })
        }
    }

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            navigate('/signup');
        } else {
            setTokenExists(true);
            const { settings } = JSON.parse(localStorage.getItem('token'));
            if (!settings.name) {
                const nameValue = localStorage.getItem("update") || "";
                setName(nameValue);
            } else {
                setName(settings.name);
                localStorage.setItem('update', settings.name);
            }
            setEmail(settings.email ? settings.email : "");
            setHandle(settings.handle ? settings.handle : "");
        }
    }, [])

    if (!tokenExists)
        return <></>;

    return (
        <div className="settings__Container">
            <div className="settings__Wrapper">
                <h4>Update Settings</h4>
                <hr />
                <form onSubmit={handleSubmit}>
                    <div className="settings__input__group">
                        <label>Name</label>
                        <input type="text" name="name" className="settings__input" onChange={(e) => setName(e.target.value)} value={name} required />
                    </div>
                    <div className="settings__input__group">
                        <label>Handle</label>
                        <input type="text" name="handle" className="settings__input" onChange={(e) => setHandle(e.target.value)} value={handle} required />
                    </div>
                    <div className="settings__input__group">
                        <label>Email</label>
                        <input type="email" name="email" className="settings__input" onChange={(e) => setEmail(e.target.value)} value={email} required />
                    </div>

                    {loading ? <button className="settings__button" disabled={true}
                    // onClick={() =>
                    //   window.location.replace(location.location.origin + "/home")
                    // }
                    >
                        Please wait..
                    </button>
                        :
                        <button className="settings__button"
                        // onClick={() =>
                        //   window.location.replace(location.location.origin + "/home")
                        // }
                        >
                            Save
                        </button>
                    }
                </form>
                <button
                    className="settings__button"
                    onClick={() => handleLogout()}
                >
                    Logout
                </button>
                <button
                    className="settings__button"
                    onClick={() => handleDelete()}
                    disabled={deleteLoading}
                >
                    {deleteLoading ? "Please Wait..." : "Delete User"}
                </button>

            </div>
        </div>
    )
}

export default UpdateSettings