import React from 'react';
import "../styles/merchandise.scss";
import Sidebar from "../components/sidebar"
import Layout from '../components/layout';
// import axios from 'axios';
import { BsFillHeartFill, BsStarFill } from 'react-icons/bs';
import { IoMdSwitch } from 'react-icons/io';
import shirt from '../images/shirt.png'
import pshirt from '../images/product_shirt.png'


const Merchandise = ({ location }) => {
    // const [data, setData] = useState([]);

    // const fetchUserData = () => {
    //     axios.get('https://api.opzoom.com/access4/test/getHomeScreen').then(res => {
    //         setData(res.data)
    //     }).catch(err => {
    //         console.log(err)
    //     })
    // }

    // useEffect(() => {
    //     fetchUserData()
    // }, [])

    return (
        <Layout location={location}>
            <div className='merchandise__container  container' >
                <div className="wrapper">
                    <Sidebar />
                    <div className="merchandise__wrapper__main">
                        <div className='merchandise__header'>
                            <IoMdSwitch />
                        </div>
                        <div className='merchandise__categories'>
                            <div className="merchandise__category">
                                <div className='category'>
                                    <img src={shirt} alt="" />
                                    <p>T-Shirt</p>
                                </div>
                            </div>
                            <div className="merchandise__category">
                                <div className='category'>
                                    <img src={shirt} alt="" />
                                    <p>T-Shirt</p>
                                </div>
                            </div>
                            <div className="merchandise__category">
                                <div className='category'>
                                    <img src={shirt} alt="" />
                                    <p>T-Shirt</p>
                                </div>
                            </div>
                            <div className="merchandise__category">
                                <div className='category'>
                                    <img src={shirt} alt="" />
                                    <p>T-Shirt</p>
                                </div>
                            </div>
                            <div className="merchandise__category">
                                <div className='category'>
                                    <img src={shirt} alt="" />
                                    <p>T-Shirt</p>
                                </div>
                            </div>
                        </div>
                        <div className='merchandise__products'>
                            <div className='merchandise__product__header'>
                                <h4>Top Products</h4>
                                <button className='product__action__btn'>See All</button>
                            </div>
                            <div className='merchandise__product__body'>
                                <div className="merchandise__product__wrapper">
                                    <div className='product_img'>
                                        <img src={pshirt} alt="" />
                                        <div className='heart__top'>
                                            <BsFillHeartFill />
                                        </div>
                                    </div>
                                    <div className='product__info'>
                                        <h4>T-Shirt</h4>
                                    </div>
                                    <div className='product__rating'>
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <span>(25)</span>
                                    </div>
                                    <div className='product__footer'>
                                        <p>$12</p>
                                        <button className='product_action_btn_2'>See More</button>
                                    </div>
                                </div>
                                <div className="merchandise__product__wrapper">
                                    <div className='product_img'>
                                        <img src={pshirt} alt="" />
                                        <div className='heart__top'>
                                            <BsFillHeartFill />
                                        </div>
                                    </div>
                                    <div className='product__info'>
                                        <h4>T-Shirt</h4>
                                    </div>
                                    <div className='product__rating'>
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <span>(25)</span>
                                    </div>
                                    <div className='product__footer'>
                                        <p>$12</p>
                                        <button className='product_action_btn_2'>See More</button>
                                    </div>
                                </div>
                                <div className="merchandise__product__wrapper">
                                    <div className='product_img'>
                                        <img src={pshirt} alt="" />
                                        <div className='heart__top'>
                                            <BsFillHeartFill />
                                        </div>
                                    </div>
                                    <div className='product__info'>
                                        <h4>T-Shirt</h4>
                                    </div>
                                    <div className='product__rating'>
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <span>(25)</span>
                                    </div>
                                    <div className='product__footer'>
                                        <p>$12</p>
                                        <button className='product_action_btn_2'>See More</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='merchandise__products'>
                            <div className='merchandise__product__header'>
                                <h4>Top Bought</h4>
                                <button className='product__action__btn'>See All</button>
                            </div>
                            <div className='merchandise__product__body'>
                                <div className="merchandise__product__wrapper">
                                    <div className='product_img'>
                                        <img src={pshirt} alt="" />
                                        <div className='heart__top'>
                                            <BsFillHeartFill />
                                        </div>
                                    </div>
                                    <div className='product__info'>
                                        <h4>T-Shirt</h4>
                                    </div>
                                    <div className='product__rating'>
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <span>(25)</span>
                                    </div>
                                    <div className='product__footer'>
                                        <p>$12</p>
                                        <button className='product_action_btn_2'>See More</button>
                                    </div>
                                </div>
                                <div className="merchandise__product__wrapper">
                                    <div className='product_img'>
                                        <img src={pshirt} alt="" />
                                        <div className='heart__top'>
                                            <BsFillHeartFill />
                                        </div>
                                    </div>
                                    <div className='product__info'>
                                        <h4>T-Shirt</h4>
                                    </div>
                                    <div className='product__rating'>
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <span>(25)</span>
                                    </div>
                                    <div className='product__footer'>
                                        <p>$12</p>
                                        <button className='product_action_btn_2'>See More</button>
                                    </div>
                                </div>
                                <div className="merchandise__product__wrapper">
                                    <div className='product_img'>
                                        <img src={pshirt} alt="" />
                                        <div className='heart__top'>
                                            <BsFillHeartFill />
                                        </div>
                                    </div>
                                    <div className='product__info'>
                                        <h4>T-Shirt</h4>
                                    </div>
                                    <div className='product__rating'>
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <span>(25)</span>
                                    </div>
                                    <div className='product__footer'>
                                        <p>$12</p>
                                        <button className='product_action_btn_2'>See More</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='merchandise__products'>
                            <div className='merchandise__product__header'>
                                <h4>Trends</h4>
                                <button className='product__action__btn'>See All</button>
                            </div>
                            <div className='merchandise__product__body'>
                                <div className="merchandise__product__wrapper">
                                    <div className='product_img'>
                                        <img src={pshirt} alt="" />
                                        <div className='heart__top'>
                                            <BsFillHeartFill />
                                        </div>
                                    </div>
                                    <div className='product__info'>
                                        <h4>T-Shirt</h4>
                                    </div>
                                    <div className='product__rating'>
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <span>(25)</span>
                                    </div>
                                    <div className='product__footer'>
                                        <p>$12</p>
                                        <button className='product_action_btn_2'>See More</button>
                                    </div>
                                </div>
                                <div className="merchandise__product__wrapper">
                                    <div className='product_img'>
                                        <img src={pshirt} alt="" />
                                        <div className='heart__top'>
                                            <BsFillHeartFill />
                                        </div>
                                    </div>
                                    <div className='product__info'>
                                        <h4>T-Shirt</h4>
                                    </div>
                                    <div className='product__rating'>
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <span>(25)</span>
                                    </div>
                                    <div className='product__footer'>
                                        <p>$12</p>
                                        <button className='product_action_btn_2'>See More</button>
                                    </div>
                                </div>
                                <div className="merchandise__product__wrapper">
                                    <div className='product_img'>
                                        <img src={pshirt} alt="" />
                                        <div className='heart__top'>
                                            <BsFillHeartFill />
                                        </div>
                                    </div>
                                    <div className='product__info'>
                                        <h4>T-Shirt</h4>
                                    </div>
                                    <div className='product__rating'>
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <span>(25)</span>
                                    </div>
                                    <div className='product__footer'>
                                        <p>$12</p>
                                        <button className='product_action_btn_2'>See More</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
};

export default Merchandise;

