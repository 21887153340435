// If you don't want to use TypeScript you can delete this file!
import * as React from "react";
import logo from "../images/LogoFinal.png";
import "../styles/index.scss";
import { Link, navigate } from "gatsby";
import { v4 as uuid } from 'uuid';


const IndexPage = ({ location }) => {


  React.useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (!user || !user.sessionID) {
      const id = uuid();
      if (user) {
        user["sessionID"] = id;
      } else {
        user = { sessionID: id }
      }

      localStorage.setItem("user", JSON.stringify(user));

    }

  }, [])

  return (
    <div className="welcomeContainer">
      <div className="welcome">
        <h6>Welcome</h6>
        <img src={logo} alt="logo"></img>
        <button
          onClick={() => {
            localStorage.setItem("ageVerified", "true");
            navigate(location.state?.redirectPage ? location.state.redirectPage : "/home");
            const date = new Date();
            localStorage.setItem("verifiedTime", date.toString());
          }
          }
        >
          I'm over 18 <br /> <span>I'm over the age of consent where I live</span>
        </button>
      </div>
    </div>
  );
};

export default IndexPage;
