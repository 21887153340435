// If you don't want to use TypeScript you can delete this file!
import React, { useState, useEffect } from 'react';
import logo from "../images/LogoFinal.png";
import "../styles/otp.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link, navigate } from "gatsby";
import OtpModal from '../components/otpModal'
import "../components/modal/Modal.scss"
import EIcon from '../images/error__icon.png'
import axios from 'axios';


const OtpPage = () => {
  const [otpValue, setotpValue] = useState({
    otp1: "", otp2: "", otp3: ""
  });
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resendMsg, setResendMeg] = useState(false);

  const handleChange = (e) => {
    setotpValue({ ...otpValue, [e.target.name]: e.target.value })
  }

  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus()
      }
    }
    else {
      const next = elmnt.target.tabIndex;
      if (next < 3) {
        elmnt.target.form.elements[next].focus()
      }
    }

  }

  const { otp1, otp2, otp3 } = otpValue;

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const user = JSON.parse(localStorage.getItem('user'));
    const code = otp1 + otp2 + otp3;
    try {
      axios.post("https://api.opzoom.com/access4/test/verify", {
        sessionID: user.sessionID,
        phone: user.phone,
        code: code
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        console.log(res.data);
        if (res.data === "") {
          setShowModal(true);
          setLoading(false);
        } else {
          localStorage.setItem('token', JSON.stringify(res.data));
          const { settings } = res.data;
          if (settings.name && settings.handle && settings.email) {
            const redirect = JSON.parse(sessionStorage.getItem("redirect"));
            if (redirect && redirect.pathname) {
              const newRedirect = { ...redirect }
              delete newRedirect["pathname"]
              sessionStorage.setItem("redirect", JSON.stringify(newRedirect))
              navigate(redirect.pathname);
            } else {
              navigate('/home');
            }
          } else {
            const redirect = {
              pathname: "/home",

            }
            sessionStorage.setItem("redirect", JSON.stringify(redirect));
            navigate('/updateSettings');
          }
          setLoading(false);
        }
      }).catch(err => {
        console.log(err);
      });

    } catch (error) {
      console.log(error);
    }
  }

  const handleResendCode = async (e) => {
    e.preventDefault();
    setResendMeg(true);
    const user = JSON.parse(localStorage.getItem('user'));
    try {
      await axios.post("https://api.opzoom.com/access4/test/send", user).then(res => {
        localStorage.setItem('code', JSON.stringify(res.data));
        navigate('/otp');
      }).catch(err => {
        console.log(err);
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="opt__Container">
      <div className="opt__Wrapper">
        <div className="opt__image"><img src={logo} alt="logo" className="logo"></img></div>
        <h4>Log In/Sign Up</h4>
        <p>We sent a code to your phone number</p>
        <form onSubmit={handleSubmit}>
          <div className="otp__input__group">
            <div className="otp__input">
              <input type="text" name="otp1" maxLength="1" autoComplete="off" required
                onChange={handleChange}
                tabIndex="1" onKeyUp={e => inputfocus(e)}
              />
            </div>
            <div className="otp__input">
              <input type="text" name="otp2" maxLength="1" autoComplete="off" required
                onChange={handleChange}
                tabIndex="2" onKeyUp={e => inputfocus(e)}
              />
            </div>
            <div className="otp__input">
              <input type="text" name="otp3" maxLength="1" autoComplete="off" required
                onChange={handleChange}
                tabIndex="3" onKeyUp={e => inputfocus(e)}
              />
            </div>
          </div>
          <div className="resend__code">
            Didn't get code?
            <button className='resend__button' onClick={handleResendCode}>( Resend )</button>
          </div>
          {resendMsg && <p className='resend__code__msg'>Resend code successfully.</p>}
          {loading ? <button className="otp__button" disabled={true}
          // onClick={() =>
          //   window.location.replace(location.location.origin + "/home")
          // }
          >
            Please wait..
          </button>
            :
            <button className="otp__button"
            // onClick={() =>
            //   window.location.replace(location.location.origin + "/home")
            // }
            >
              Verify
            </button>
          }
        </form>
      </div>
      {showModal &&
        <OtpModal showModal={showModal} setShowModal={setShowModal} >
          <div className='modal__body'>
            <img src={EIcon} alt="modal Icon" />
            <h4>We are Sorry!</h4>
            <p>Your verification code didn't match the number <br /> we sent</p>
            <button className='btn__modal' onClick={() => setShowModal(false)}>Please Try Again</button>
            <div className='cancel' onClick={() => setShowModal(false)}>Cancel</div>
          </div>
        </OtpModal>
      }
    </div>
  );
};

export default OtpPage;
