import React, { useEffect, useState } from 'react';
import "../styles/home.scss";
import Post from '../components/post'
import Sidebar from "../components/sidebar"
import Layout from '../components/layout';
import axios from 'axios';
import { Link } from 'gatsby';
import { BsFillHeartFill, BsHeart } from 'react-icons/bs';
import { Button } from "react-bootstrap";


const Home = ({ location }) => {
    const [data, setData] = useState(null);
    const [browseData, setDataBrowse] = useState(null);

    const fetchUserData = () => {
        if (!localStorage.getItem('token')) {
            axios.post('https://api.opzoom.com/access4/test/getHomeScreen', {
                phone: "180000000"
            }).then(res => {
                setData(res.data);
            }).catch(err => {
                console.log(err);
            })
        } else {
            const { token, settings } = JSON.parse(localStorage.getItem('token'));
            axios.post('https://api.opzoom.com/access4/test/getHomeScreen', {
                phone: settings.phone
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            }).then(res => {
                setData(res.data);
            }).catch(err => {
                console.log(err);
            })
        }
    }

    const fetchBrowseData = () => {
        if (!localStorage.getItem('token')) {
            axios.post('https://api.opzoom.com/access4/test/getBrowseScreen', {
                phone: "180000000"
            }).then(res => {
                setDataBrowse(res.data);
            }).catch(err => {
                console.log(err);
            })
        } else {
            const { token, settings } = JSON.parse(localStorage.getItem('token'));
            axios.post('https://api.opzoom.com/access4/test/getBrowseScreen', {
                phone: settings.phone
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            }).then(res => {
                setDataBrowse(res.data);
            }).catch(err => {
                console.log(err);
            })
        }
    }

    useEffect(() => {
        fetchUserData();
        fetchBrowseData();
    }, [])

    return (
        <Layout location={location}>
            <div className='home__container  container' >
                <div className="wrapper">
                    <Sidebar />
                    <div className="post__wrapper__main">
                        {data?.map((item, index) => (
                            <>
                                <Post item={item} key={index} location={location} />
                                {
                                    ((index + 1) % 6 === 0) ?
                                        <div>
                                            <img src="https://access4-media.opzoom.com/ads/002.jpg" alt="ads_002" className='ads__2' />
                                        </div>
                                        :
                                        ((index + 1) % 3 === 0) ?
                                            <div>
                                                <img src="https://access4-media.opzoom.com/ads/001.jpg" alt="ads_001" className='ads__1' />
                                            </div>
                                            : <div />
                                }
                            </>
                        )

                        )}

                        <div className='browse__block'>
                            {browseData?.map((browse, index) => (
                                <div className="browse__avatar" key={index}>
                                    <div className='avatar'>
                                        <Link to={`/profile/${browse?.handle}`} state={{ handle: browse?.handle }} ><img src={browse?.profile_image_url} alt="" /></Link>
                                        {browse?.i_follow === 0 ? <div className='heart__top'>
                                            <BsHeart />
                                        </div>
                                            :
                                            <div className='heart__top__red'>
                                                <BsFillHeartFill />
                                            </div>}
                                    </div>
                                    <div className='avatar__info'>
                                        <h4>{browse.name}</h4>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div id="browse_link_container">
                            <Link to="/browse">
                                <Button
                                    variant="danger"
                                >
                                    Browse
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
};

export default Home;

