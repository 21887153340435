import React, { useEffect, useRef, useState } from 'react';
import Sidebar from '../components/sidebar';
import { BsFillHeartFill, BsHeart, BsCashCoin, BsWallet, BsPaypal, BsPinterest, BsThreeDotsVertical } from "react-icons/bs"
import "../styles/profile.scss"
import Avatar from "../images/avatar.png"
import axios from 'axios';
import { MdAddComment } from "react-icons/md"
import { AiFillLike, AiOutlineDislike, AiOutlineLike, AiOutlinePlus } from "react-icons/ai"
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io"
import { Carousel } from 'react-responsive-carousel';
import Layout from "../components/layout";
import { Link, navigate } from 'gatsby';
import { useOnScreen } from '../components/post';
import { MdZoomOutMap, MdDelete } from "react-icons/md"
import { BiSend } from "react-icons/bi"
import { AiFillPlayCircle } from "react-icons/ai"
import { FaFacebookSquare, FaTwitterSquare, FaInstagramSquare, FaSnapchatSquare, FaAmazon } from "react-icons/fa"
import { SiOnlyfans, SiVenmo } from "react-icons/si"
import { BiCalendarEdit } from 'react-icons/bi';
import { FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import { Button, NavDropdown, Spinner } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ExternalLinks from "../components/externLinks";
import Modal from "../components/modal";


const videoStyle = {
    maxHeight: "100%"
}

const nameStyle = {
    fontWeight: 600,
    color: "#707070",
}



const Profile = ({ location }) => {

    const [pageLoading, setPageLoading] = useState(true);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isFollow, setFollow] = useState(data?.settings?.i_follow === 1 ? true : false);
    const [numberOfFollowers, setNumberOfFollowers] = useState(0);
    const [handle, setHandle] = useState(location && location.state ? location.state.handle : "");
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("ageVerified") !== "true") {
            navigate("/checkAge", {
                state: {
                    redirectPage: location.pathname
                }
            });
        } else {
            if (!handle) {
                const ulrParts = location.pathname.split("/");
                if (ulrParts.length >= 3 && ulrParts[2]) {
                    setHandle(ulrParts[2]);
                    setPageLoading(false);
                } else {
                    navigate("/404");
                }
            } else {
                setPageLoading(false);
            }
        }
    }, [location]);

    const fetchUserData = () => {
        if (!localStorage.getItem('token')) {
            axios.post('https://api.opzoom.com/access4/test/getProfileScreen', {
                handle: handle
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => {
                    console.log(res);
                    setData(res.data);
                    setDataLoaded(true);
                })
                .catch(err => {
                    console.log(err);
                    navigate("/404");
                })
        } else {
            const { token } = JSON.parse(localStorage.getItem('token'));
            axios.post('https://api.opzoom.com/access4/test/getProfileScreen', {
                handle: handle
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
                .then(res => {
                    console.log("Response: ", res.data);
                    setData(res.data);
                    setDataLoaded(true);
                    const redirect = JSON.parse(sessionStorage.getItem("redirect"));
                    if (redirect && redirect.follow) {
                        handleFollow({ preventDefault: () => false })
                        sessionStorage.removeItem("redirect")
                    }
                })
                .catch(err => {
                    console.log(err);
                    navigate("/404");
                })
        }

    }

    const handleFollow = (e) => {
        if (!localStorage.getItem('token')) {
            const redirect = {
                pathname: location.pathname,
                follow: true
            }
            sessionStorage.setItem("redirect", JSON.stringify(redirect));
            navigate('/signup');

        } else {
            e.preventDefault();
            setLoading(true);
            const { settings, token } = JSON.parse(localStorage.getItem('token'));
            axios.post("https://api.opzoom.com/access4/test/addFollow", {
                handle: handle
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            }).then(res => {
                console.log(res.data);
                setLoading(false);
                setFollow(!isFollow);
                setNumberOfFollowers(numberOfFollowers + 1);
            }).catch(err => {
                console.log(err);
                setLoading(false)
            });
        }
    }

    const handleUnFollow = (e) => {
        if (!localStorage.getItem('token')) {
            navigate('/signup');
        } else {
            e.preventDefault();
            setLoading(true);
            const { token } = JSON.parse(localStorage.getItem('token'));
            try {
                axios.post("https://api.opzoom.com/access4/test/unFollow", {
                    handle: handle
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token
                    }
                }).then(res => {
                    console.log(res.data);
                    setLoading(false);
                    setFollow(!isFollow);
                    setNumberOfFollowers(numberOfFollowers - 1);
                }).catch(err => {
                    console.log(err);
                });
            } catch (error) {
                console.log(error);
                setLoading(true);
            }
        }
    }

    useEffect(() => {
        if (location && handle) fetchUserData();
        console.log(location);
    }, [location, handle]);

    useEffect(() => {
        if (dataLoaded && (!data.settings || !data.settings.user || !data.settings.user.name)) {
            navigate("/404")
        }

    }, [dataLoaded])

    useEffect(() => {
        if (data && data.settings && data.settings.i_follow === 1) {
            setFollow(true)
        } else {
            setFollow(false)
        }

        if (data && data.settings && data.settings.num_follows)
            setNumberOfFollowers(data.settings.num_follows);

    }, [data])


    return (
        <React.Fragment>
            {pageLoading
                ? <div className="profile__loader">
                    <Spinner animation="border" />
                </div>
                : <Layout location={location}>
                    <div className='profile__container container'>
                        <div className="wrapper">
                            <Sidebar />
                            <div className="profile__wrapper__main">
                                <div className="profile__wrapper">
                                    <div className="profile__cover">
                                        <img src={data?.settings?.profile_header_url} alt="" />
                                    </div>
                                    <div className="profile__section">
                                        <div>
                                            <div className='profile__section_header' >
                                                <div>
                                                    <div className='profile__section_avatar'>
                                                        <div className='avatar' >
                                                            <img src={data?.settings?.profile_image_url} alt="" />
                                                        </div>
                                                        {!isFollow ? <div className='heart__top'>
                                                            <BsHeart />
                                                        </div>
                                                            :
                                                            <div className='heart__top__active'>
                                                                <BsFillHeartFill />
                                                            </div>}
                                                    </div>
                                                    <div className="avatar__info">
                                                        <h4>{data?.settings?.user.name}</h4>
                                                        <div>
                                                            <div><BiCalendarEdit /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="profile__section_bio">
                                                <p style={nameStyle} >{data?.settings?.user.name}</p>
                                                <p>{data?.settings?.user.description}</p>
                                                <p>{data?.settings?.user.email}</p>
                                                <p></p>
                                            </div>
                                        </div>
                                        <div>
                                            {loading ? <Button variant="danger" disabled>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            </Button> :
                                                isFollow ?
                                                    <button className='unfollow__btn' onClick={handleUnFollow}>UnFollow</button>
                                                    :
                                                    <button className='follow__btn' onClick={handleFollow}>Follow</button>
                                            }
                                            <button className='merchandise__btn' onClick={() => navigate('/merchandise')}>Merchandise</button>
                                        </div>
                                        <p className='followers'>({numberOfFollowers}) Followers</p>
                                        <div className='swiper__container__linktree'>
                                            <ExternalLinks links={data?.settings?.user?.links} />
                                        </div>
                                    </div>
                                    <div className="profile__title">
                                        <h3>YourPost</h3>
                                    </div>
                                </div>
                                <div className="post__wrapper__main">
                                    {data?.panels?.map((post, index) => (
                                        <>
                                            <Post post={post} userData={data?.settings} key={index} location={location} />
                                            {
                                                ((index + 1) % 6 === 0) ?
                                                    <div>
                                                        <img src="https://access4-media.opzoom.com/ads/002.jpg" alt="ads_002" className='ads__2' />
                                                    </div>
                                                    :
                                                    ((index + 1) % 3 === 0) ?
                                                        <div>
                                                            <img src="https://access4-media.opzoom.com/ads/001.jpg" alt="ads_001" className='ads__1' />
                                                        </div>
                                                        : <div />
                                            }
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout>
            }
        </React.Fragment>

    )
};


const Post = ({ post, userData, location }) => {
    const [showModal, setShowModal] = useState(false);
    const [showComment, setShowComment] = useState(true);
    const [playing, setPlaying] = useState(false);
    const [loadingComment, setloadingComment] = useState(false);
    const [loadingDelComment, setloadingDelComment] = useState(false);
    const [likeLoading, setLikeLoading] = useState(false);
    const [isLiked, setIsLiked] = useState(post?.i_like === 1 ? true : false);
    const [comment, setComment] = useState("");
    const [comments, setComments] = useState([]);
    const [visibleComments, setVisibleComments] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const [showCommentBox, setShowCommentBox] = useState(false);
    const [numLikes, setNumLikes] = useState(0);
    const [user, setUser] = useState(null);
    const [playedOnce, setPlayedOnce] = useState(false)

    const [ref, visible] = useOnScreen({
        rootMargin: '0px',
        threshold: 0.3,
    })

    const handleComment = () => {
        if (!localStorage.getItem('token')) {
            const redirect = {
                pathname: location.pathname,
            }
            sessionStorage.setItem("redirect", JSON.stringify(redirect));
            navigate('/signup');
        } else {
            setShowCommentBox(true);
            if (!showComment) setShowComment(true)
        }
    }

    const handleAddComment = (e) => {
        e.preventDefault();
        setloadingComment(true);
        const pannel_id = userData?.user.handle + "-" + post?.slno;
        console.log(pannel_id);
        const { token } = JSON.parse(localStorage.getItem('token'));

        axios.post("https://api.opzoom.com/access4/test/addComment", {
            panel_id: pannel_id,
            text: comment
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(res => {
            setComment("");
            setloadingComment(false);
            const newComments = [...comments];
            const { settings } = JSON.parse(localStorage.getItem("token"));
            newComments.unshift({
                id: res.data.ts,
                name: settings.name,
                text: comment
            });
            setComments(newComments);
        }).catch(err => {
            console.log(err);
            setloadingComment(false);
        });

    }

    const handleDelComment = (id, commentIndex) => {
        setloadingDelComment(true);
        const { token } = JSON.parse(localStorage.getItem('token'));

        axios.post("https://api.opzoom.com/access4/test/delComment", {
            id: id
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(res => {
            setloadingDelComment(false);
            const newComments = [...comments];
            newComments.splice(commentIndex, 1);
            console.log("New Comments: ", newComments);
            setComments(newComments);
        }).catch(err => {
            console.log(err);
            setloadingDelComment(false);
        });

    }

    const handleLike = () => {
        if (!localStorage.getItem('token')) {
            const redirect = {
                pathname: location.pathname,
                like: true
            }
            sessionStorage.setItem("redirect", JSON.stringify(redirect));
            navigate('/signup');
        } else {
            setLikeLoading(true);
            const { token } = JSON.parse(localStorage.getItem('token'));
            const pannel_id = userData?.user.handle + "-" + post?.slno;

            axios.post("https://api.opzoom.com/access4/test/addLike", {
                panel_id: pannel_id
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            }).then(res => {
                console.log(res.data);
                setLikeLoading(false);
                // setLike(isLiked ? like - 1 : like + 1);
                setNumLikes(numLikes + 1)
                setIsLiked(!isLiked);
            }).catch(err => {
                console.log(err);
                setLikeLoading(false);
            });

        }
    };

    const handleDisLike = () => {
        setLikeLoading(true);
        const { token } = JSON.parse(localStorage.getItem('token'));
        const pannel_id = userData?.user.handle + "-" + post?.slno;

        axios.post("https://api.opzoom.com/access4/test/disLike", {
            panel_id: pannel_id
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then(res => {
            console.log(res.data);
            setLikeLoading(false);
            // setLike(isLiked ? like - 1 : like + 1);
            setNumLikes(numLikes - 1)
            setIsLiked(!isLiked);
        }).catch(err => {
            console.log(err);
            setLikeLoading(false);
        });

    }
    // toggle Body scroll when modal's open and closed
    const toggleBodeScroll = () => {
        if (showModal) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"
        }
    }

    // get url extention coming from api to decide on video autoplay if its video

    const getUrlExtension = (url) => {
        return url.split(/[#?]/)[0].split('.').pop().trim();
    }

    // video onclick play pause with handleVideoPress

    const handleVideoPress = () => {

        if (playing) {
            ref.current.pause();
            setPlaying(false);
        }
        else {
            ref.current.play();
            setPlaying(true);
        }
    };

    const handleVideoPress_zoom = () => {

        if (playing) {
            ref.current.pause();
            setPlaying(false);

        }
        else {
            ref.current.play();
            setPlaying(true);
        }
    };

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("token"));
        if (token && token.token) {
            setUser({
                name: token.settings.name
            })
        }
    }, [])


    useEffect(() => {
        if (post && post.comments)
            setComments(post.comments)
        if (post && post.num_likes)
            setNumLikes(post.num_likes)
        const redirect = JSON.parse(sessionStorage.getItem("redirect"));
        if (redirect && redirect.like) {
            handleLike()
            sessionStorage.removeItem("redirect")
        }
    }, [post])



    useEffect(() => {
        if (comments.length >= 2 && !showMore) {
            setVisibleComments(comments.slice(0, 2))
        } else {
            setVisibleComments(comments)
        }
    }, [comments]);


    useEffect(() => {
        if (showMore) {
            setVisibleComments(comments);
        } else {
            if (comments.length >= 2) {
                setVisibleComments(comments.slice(0, 2))
            } else {
                setVisibleComments(comments);
            }
        }

    }, [showMore])

    useEffect(() => {
        if (post && post.comments && post.comments.length <= 2) {
            setShowMore(true)
        }
    }, [])

    useEffect(() => {
        if (visible && ref.current && playedOnce) {
            ref.current.play()
            setPlaying(true)
        } else if (ref.current) {
            ref.current.pause()
            setPlaying(false)
        }
    }, [visible, playedOnce])

    useEffect(() => {
        if (playedOnce && ref.current) {
            ref.current.setAttribute("controls", "")

        }

    }, [playedOnce])



    useEffect(() => {
        toggleBodeScroll();
    }, [showModal])

    return (

        <>
            {console.log(post)}
            {console.log(userData)}
            <div className='post' >
                <div className="post__top">
                    <div className='post__top_avatar' >
                        <div className='avatar' >
                            <img src={userData?.profile_image_url} alt="" />
                        </div>
                        <div className="avatar__info">
                            <h4>{userData?.user.name}</h4>
                        </div>
                    </div>
                </div>
                <div className="post__carousel">
                    {post?.content_images?.length === 1 &&
                        <div>
                            {post?.content_images.map((image, i) => (
                                <div className='image__wrapper'>
                                    <div className="carousel__image" key={i} style={getUrlExtension(image) === "mp4" ? videoStyle : {}} >
                                        {getUrlExtension(image) === "mp4" &&
                                            <video
                                                src={image}
                                                onClick={() => {
                                                    if (!playedOnce) {
                                                        setPlayedOnce(true)
                                                    }

                                                }}
                                                ref={ref}
                                                muted
                                            ></video>}
                                        {getUrlExtension(image) === "jpg" &&
                                            <LazyLoadImage
                                                effect='blur'
                                                src={image}
                                                onClick={() => setShowModal(true)}
                                                alt="" />}
                                        {(getUrlExtension(image) === "mp4" && !playing) ? <AiFillPlayCircle className='playPause__icons' /> : null}

                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                    {post?.content_images?.length > 1 &&
                        <div>
                            <Carousel
                                showThumbs={false}
                                swipeable={true}
                                emulateTouch={true}
                                showStatus={false}
                                showArrows={true}
                                centerMode={true}
                                selectedItem={1}
                                infiniteLoop={true}
                                onClickItem={() => setShowModal(true)}
                                preventMovementUntilSwipeScrollTolerance={true}
                            >
                                {post?.content_images.map((image, i) => (
                                    <div className="carousel__image" key={i} style={getUrlExtension(image) === "mp4" ? videoStyle : {}} >
                                        {getUrlExtension(image) === "mp4" &&
                                            <video
                                                src={image}
                                                onClick={() => setShowModal(true)}
                                                ref={ref}
                                                loop={true}
                                                autoPlay
                                                muted
                                            ></video>}
                                        {getUrlExtension(image) === "jpg" &&
                                            <LazyLoadImage
                                                effect='blur'
                                                src={image}
                                                onClick={() => setShowModal(true)}
                                                alt="" />}
                                        {(getUrlExtension(image) === "mp4" && !playing) ? <AiFillPlayCircle className='playPause__icons' /> : null}
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    }
                </div>
                <div className="post__bottom_engagement">

                    <div>
                        <div className="comment__count">
                            <div className="comment__count__dekstop">
                                <div>{comments.length} <span>Comments</span></div>
                            </div>
                            {showComment ? <Button className="comment__dropdown__container" variant="danger" size="sm" onClick={() => {
                                setShowComment(false);
                            }}>
                                <span className="comment__dropdown__text">Hide</span>
                                <IoIosArrowUp size={11} cursor={"pointer"} />
                            </Button> : <Button className="comment__dropdown__container" variant="danger" size="sm" onClick={() => setShowComment(true)} >
                                <span className="comment__dropdown__text">Show</span>
                                <IoIosArrowDown size={11} cursor={"pointer"} />
                            </Button>}
                            {!showCommentBox && <Button onClick={handleComment} variant="danger" size="sm">
                                <span className="add__comment__mobile">+</span>
                                <span className="add__comment__dekstop">Add Comment</span>
                            </Button>}
                            <div className="comment__count__mobile">
                                <div>{comments.length} <span>Comments</span></div>
                            </div>
                        </div>
                        <div className='like__block'>
                            <div className="like__count__container">
                                {isLiked ? <AiFillLike color={"#d50b29"} /> : <AiOutlineLike />}
                                {numLikes && `  ${numLikes}`}
                            </div>
                            {isLiked ? <Button onClick={handleDisLike} variant={"secondary"} size={"sm"} className={"like__container"}>{likeLoading ? <Spinner animation={"border"} size={"sm"} /> : "Unlike"}</Button>
                                : <Button onClick={handleLike} variant={"danger"} size={"sm"} className={"like__container"}>{likeLoading ? <Spinner animation={"border"} size={"sm"} /> : "Like"}</Button>


                            }
                        </div>
                    </div>
                    {showComment && <>
                        {showCommentBox && <div className='comment__box'>
                            <img src={Avatar} alt='icon' className='rounded__image' />
                            <form onSubmit={handleAddComment}>
                                <input type="text" placeholder='Write a comment...' onChange={(e) => setComment(e.target.value)} value={comment} required />
                                {loadingComment ? <Spinner animation='border' /> : <button className='send_button'><BiSend /></button>}
                            </form>
                        </div>}
                        <div className='comment__contents'>
                            {loadingDelComment && <p>Comment is deleting..</p>}
                            {visibleComments.map((comment_text, index) => (
                                <div className='comment__inner' key={index}>
                                    <div className='comment__content'>
                                        <img src={Avatar} alt='icon' className='rounded__image__small' />
                                        <div className='comment__info'>
                                            <p className='user__name'>{comment_text.name === null ? "Anonymous" : comment_text.name}</p>
                                            <p>{comment_text.text}</p>
                                        </div>
                                    </div>
                                    {user && user.name === comment_text.name && <div className='comment__delete'>
                                        <button onClick={() => handleDelComment(comment_text.id, index)} className="comment__del__button"><MdDelete /></button>
                                    </div>}
                                </div>
                            ))}
                            <div className="show__more__container">
                                {!showMore && <Button className="show__more" variant="danger" size="sm" onClick={() => setShowMore(!showMore)}>Show More</Button>}
                            </div>

                        </div>
                    </>}
                </div>
            </div>
            {showModal &&
                <Modal showModal={showModal} setShowModal={setShowModal} setPlaying={setPlaying} >
                    <div className="post__carousel">
                        {post?.content_images?.length > 1 ?
                            <Carousel
                                showThumbs={false}
                                swipeable={true}
                                emulateTouch={true}
                                showStatus={false}
                                showArrows={true}
                                centerMode={true}
                                infiniteLoop={true}
                                preventMovementUntilSwipeScrollTolerance={true}
                            >

                                {post?.content_images.map((image, i) => (
                                    <div className="carousel__image" key={i} style={getUrlExtension(image) === "mp4" ? videoStyle : {}} >
                                        {getUrlExtension(post?.content_images[0]) === "mp4" && <video onClick={handleVideoPress_zoom} ref={ref} src={image} autoPlay={false} loop={true}></video>}
                                        {getUrlExtension(post?.content_images[0]) === "jpg" && <LazyLoadImage effect='blur' src={image} alt="" />}
                                        {(getUrlExtension(post?.content_images[0]) === "mp4" && !playing) ? <AiFillPlayCircle className='playPause__icons' /> : null}
                                    </div>
                                ))}
                            </Carousel> :
                            <div className="carousel__image" style={getUrlExtension(post?.content_images[0]) === "mp4" ? videoStyle : {}}  >
                                {getUrlExtension(post?.content_images[0]) === "mp4" && <video onClick={handleVideoPress_zoom} ref={ref} src={post?.content_images[0]} autoPlay={false} loop={true}></video>}
                                {getUrlExtension(post?.content_images[0]) === "jpg" && <LazyLoadImage effect='blur' src={post?.content_images[0]} alt="" />}
                                {(getUrlExtension(post?.content_images[0]) === "mp4" && !playing) ? <AiFillPlayCircle className='playPause__icons' /> : null}
                            </div>
                        }
                    </div>

                </Modal>
            }
        </>

    )
};


export default Profile;
