exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-browse-js": () => import("./../../../src/pages/browse.js" /* webpackChunkName: "component---src-pages-browse-js" */),
  "component---src-pages-check-age-js": () => import("./../../../src/pages/checkAge.js" /* webpackChunkName: "component---src-pages-check-age-js" */),
  "component---src-pages-continue-js": () => import("./../../../src/pages/continue.js" /* webpackChunkName: "component---src-pages-continue-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-merchandise-js": () => import("./../../../src/pages/merchandise.js" /* webpackChunkName: "component---src-pages-merchandise-js" */),
  "component---src-pages-otp-js": () => import("./../../../src/pages/otp.js" /* webpackChunkName: "component---src-pages-otp-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-update-settings-js": () => import("./../../../src/pages/updateSettings.js" /* webpackChunkName: "component---src-pages-update-settings-js" */)
}

