import React, { useEffect, useRef, useState } from 'react';
import Sidebar from '../components/sidebar';
import { BsFillHeartFill, BsCashCoin, BsWallet, BsPaypal, BsPinterest, BsThreeDotsVertical } from "react-icons/bs"
import "../styles/search.scss"
import Model from "../images/model.jpg"
import axios from 'axios';
import { MdAddComment } from "react-icons/md"
import { AiFillLike, AiOutlineLike, AiOutlinePlus } from "react-icons/ai"
import { Carousel } from 'react-responsive-carousel';
import Layout from "../components/layout";
import { Link, navigate } from 'gatsby';
import { useOnScreen } from '../components/post';
import { MdZoomOutMap, MdDelete } from "react-icons/md"
import { BiSend } from "react-icons/bi"
import { FaMapMarkerAlt } from "react-icons/fa"
import { AiFillPlayCircle } from "react-icons/ai"
import { FaFacebookSquare, FaTwitterSquare, FaInstagramSquare, FaSnapchatSquare, FaAmazon } from "react-icons/fa"
import { SiOnlyfans, SiVenmo } from "react-icons/si"
import Modal from '../components/modal';
import { Button, NavDropdown, Spinner } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { BiCalendarEdit } from 'react-icons/bi';

const videoStyle = {
    maxHeight: "100%"
}

const nameStyle = {
    fontWeight: 600,
    color: "#707070",
}

const Search = ({ location }) => {
    const [tagData, setTagData] = useState(null);
    const [search, setSearchData] = useState(null);
    const [searchName, setsearchName] = useState(null);

    const fetchTagsData = () => {
        if (!localStorage.getItem('token')) {
            try {
                axios.post("https://api.opzoom.com/access4/test/getTagList", {
                    name: searchName
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => {
                        console.log(res);
                        setTagData(res.data);
                    })
                    .catch(err => {
                        console.log(err)
                    });
            } catch (error) {
                console.log(error);
            }
        } else {
            const { token } = JSON.parse(localStorage.getItem('token'));
            try {
                axios.post("https://api.opzoom.com/access4/test/getTagList", {
                    name: searchName
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token
                    }
                })
                    .then(res => {
                        console.log(res);
                        setTagData(res.data);
                    })
                    .catch(err => {
                        console.log(err)
                    });
            } catch (error) {
                console.log(error);
            }
        }
    }

    const fetchSearchData = () => {
        if (!localStorage.getItem('token')) {
            try {
                axios.post('https://api.opzoom.com/access4/test/getSearchScreen', {
                    name: "hottie"
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(res => {
                    setSearchData(res.data)
                }).catch(err => {
                    console.log(err)
                })
            } catch (error) {
                console.log(error);
            }
        } else {
            const { token } = JSON.parse(localStorage.getItem('token'));
            try {
                axios.post('https://api.opzoom.com/access4/test/getSearchScreen', {
                    name: "hottie"
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token
                    }
                }).then(res => {
                    setSearchData(res.data)
                }).catch(err => {
                    console.log(err)
                })
            } catch (error) {
                console.log(error);
            }
        }
    }

    const handleClick = (e) => {
        setsearchName(e.target.value);
        fetchTagsData();
    }

    const [playing, setPlaying] = useState(false);
    const [ref, visible] = useOnScreen({
        rootMargin: '0px',
        threshold: 0.3,
    })


    const getUrlExtension = (url) => {
        return url.split(/[#?]/)[0].split('.').pop().trim();
    }

    const handleVideoPress = () => {
        if (playing) {
            ref.current.pause()
            setPlaying(false);
        } else {
            ref.current.play();
            setPlaying(true);
        }
    };

    const handleVideoPress_zoom = () => {
        if (playing) {
            ref.current.pause()
            setPlaying(false);
        } else {
            ref.current.play();
            setPlaying(true);
        }
    };

    useEffect(() => {
        if (visible) {
            ref.current.play()
            setPlaying(true)
        } else if (ref.current) {
            ref.current.pause()
            setPlaying(false)
        }
    }, [visible])

    useEffect(() => {
        fetchSearchData();
        fetchTagsData();
    }, [tagData]);

    return (
        <Layout location={location}>
            <div className='home__container  container' >
                <div className="wrapper">
                    <Sidebar />
                    <div className="search__wrapper__main">
                        <div className="search__wrapper__top">
                            <h4>Creator of the Day</h4>
                            <div className='top__btn'>
                                <button className='btn__surprise'>Surprise Me</button>
                                <button className='btn__creators' onClick={(e) => handleClick(e, "value")} value="new">New Creators</button>
                            </div>
                        </div>
                        <div className='search__section__header' >
                            <div className='search__section__avatar'>
                                <div className='avatar' >
                                    <img src={Model} alt="" />
                                    <div className='heart__top'>
                                        <BsFillHeartFill />
                                    </div>
                                </div>
                            </div>
                            <div className="search__avatar__info">
                                <h4>Jessica Ryan</h4>
                                <div className='search__edit'>
                                    <div><BiCalendarEdit /></div>
                                </div>
                                <div className='search__mark'>
                                    <div><FaMapMarkerAlt /></div>
                                    <p>California, USA</p>
                                </div>
                            </div>
                        </div>

                        <div className='search__section__actions'>
                            <button className='btn__about'>
                                <Link to='/profile/jessica.ryan' state={{ handle: 'jessica.ryan' }}>View</Link>
                            </button>
                            {/* <button className='btn__activities'>Activities</button> */}
                        </div>

                        <div className='search__section__footer'>
                            <h4>Search By:</h4>
                            <div className='search__inputs'>
                                <div className='search__tags'>
                                    <div className='tag__container'>
                                        {search?.tags?.map((s, i) => (
                                            <button className='tag__button' onClick={(e) => handleClick(e, "value")} value={s} key={i}>{s}</button>
                                        ))}
                                    </div>
                                </div>
                                {tagData ?
                                    <div>
                                        {tagData.map((post, index) => (
                                            <>
                                                <Post t={post} key={index} />
                                                {
                                                    ((index + 1) % 6 === 0) ?
                                                        <div>
                                                            <img src="https://access4-media.opzoom.com/ads/002.jpg" alt="ads_002" className='ads__2' />
                                                        </div>
                                                        :
                                                        ((index + 1) % 3 === 0) ?
                                                            <div>
                                                                <img src="https://access4-media.opzoom.com/ads/001.jpg" alt="ads_001" className='ads__1' />
                                                            </div>
                                                            : <div />
                                                }
                                            </>
                                        ))}
                                    </div>
                                    :
                                    <div>Please wait..</div>
                                }
                            </div>

                            <div className="search__wrapper__bottom">
                                <div className='top__btn'>
                                    <button className='btn__surprise'>Surprise Me</button>
                                    <button className='btn__creators'>New Creators</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
};

const Post = ({ t }) => {
    const [showModal, setShowModal] = useState(false);
    const [showComment, setShowComment] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [loadingComment, setloadingComment] = useState(false);
    const [loadingDelComment, setloadingDelComment] = useState(false);
    const [likeLoading, setLikeLoading] = useState(false);
    const [isLiked, setIsLiked] = useState(t?.i_like === 1 ? true : false);
    const [comment, setComment] = useState("");

    const [ref, visible] = useOnScreen({
        rootMargin: '0px',
        threshold: 0.3,
    })

    const handleComment = () => {
        if (!localStorage.getItem('token')) {
            navigate('/signup');
        } else {
            setShowComment(!showComment);
        }
    }

    const handleAddComment = (e) => {
        e.preventDefault();
        setloadingComment(true);
        const handle = t?.name.toLowerCase().replace(/\s/g, ".");
        const pannel_id = handle + '-' + t?.slno;
        console.log(pannel_id);
        const { token } = JSON.parse(localStorage.getItem('token'));
        try {
            axios.post("https://api.opzoom.com/access4/test/addComment", {
                panel_id: pannel_id,
                text: comment
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            }).then(res => {
                setComment("");
                console.log(res.data);
                setloadingComment(false);
            }).catch(err => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
            setloadingComment(true);
        }
    }

    const handleDelComment = (id) => {
        setloadingDelComment(true);
        const { token } = JSON.parse(localStorage.getItem('token'));
        try {
            axios.post("https://api.opzoom.com/access4/test/delComment", {
                id: id
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            }).then(res => {
                console.log(res.data);
                setloadingDelComment(false);
            }).catch(err => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    }


    const handleLike = () => {
        if (!localStorage.getItem('token')) {
            navigate('/signup');
        } else {
            setLikeLoading(true);
            const { token } = JSON.parse(localStorage.getItem('token'));
            const handle = t?.name.toLowerCase().replace(/\s/g, ".");
            const pannel_id = handle + '-' + t?.slno;
            try {
                axios.post("https://api.opzoom.com/access4/test/addLike", {
                    panel_id: pannel_id
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token
                    }
                }).then(res => {
                    console.log(res.data);
                    setLikeLoading(false);
                    // setLike(isLiked ? like - 1 : like + 1);
                    setIsLiked(!isLiked);
                }).catch(err => {
                    console.log(err);
                });
            } catch (err) {
                console.log(err);
            }
        }
    };

    const handleDisLike = () => {
        setLikeLoading(true);
        const { token } = JSON.parse(localStorage.getItem('token'));
        const handle = t?.name.toLowerCase().replace(/\s/g, ".");
        const pannel_id = handle + '-' + t?.slno;
        try {
            axios.post("https://api.opzoom.com/access4/test/disLike", {
                panel_id: pannel_id
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            }).then(res => {
                console.log(res.data);
                setLikeLoading(false);
                // setLike(isLiked ? like - 1 : like + 1);
                setIsLiked(!isLiked);
            }).catch(err => {
                console.log(err);
            });
        } catch (err) {
            console.log(err);
        }
    }

    // toggle Body scroll when modal's open and closed
    const toggleBodeScroll = () => {
        if (showModal) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"
        }
    }

    // get url extention coming from api to decide on video autoplay if its video

    const getUrlExtension = (url) => {
        return url.split(/[#?]/)[0].split('.').pop().trim();
    }

    // video onclick play pause with handleVideoPress

    const handleVideoPress = () => {

        if (playing) {
            ref.current.pause();
            setPlaying(false);
        }
        else {
            ref.current.play();
            setPlaying(true);
        }
    };

    const handleVideoPress_zoom = () => {

        if (playing) {
            ref.current.pause();
            setPlaying(false);

        }
        else {
            ref.current.play();
            setPlaying(true);
        }
    };

    useEffect(() => {
        if (visible) {
            ref.current.play()
            setPlaying(true)
        } else if (ref.current) {
            ref.current.pause()
            setPlaying(false)
        }
    }, [visible])


    useEffect(() => {
        toggleBodeScroll();
    }, [showModal])

    return (

        <>
            {t && t.content_images ?
                <>
                    <div className='post'>
                        <div className="post__top">
                            <div className='post__top_avatar' >
                                <div className='avatar' >
                                    <Link to={`/profile/${t?.name.toLowerCase().replace(/\s/g, ".")}`} state={{ handle: t?.name.toLowerCase().replace(/\s/g, ".") }} ><img src={t?.profile_image_url} alt="" /></Link>
                                </div>
                                <div className="avatar__info">
                                    <h4>{t.name}</h4>
                                    <p>UI/UX Designer</p>
                                </div>
                            </div>
                            <div className="post__top_setting">
                                <div className="post__top_setting">
                                    <NavDropdown
                                        title={
                                            <div>
                                                <BsThreeDotsVertical />
                                            </div>
                                        }
                                        id='collasible-nav-dropdown'>
                                        <NavDropdown.Item href='#'><FaTwitterSquare /> <span>Twitter</span></NavDropdown.Item>
                                        <NavDropdown.Item href='#'><SiOnlyfans /> <span>Only Fans</span></NavDropdown.Item>
                                        <NavDropdown.Item href='#'><FaInstagramSquare /> <span>Instagram</span></NavDropdown.Item>
                                        <NavDropdown.Item href='#'><FaFacebookSquare /> <span>Facebook</span></NavDropdown.Item>
                                        <NavDropdown.Item href='#'><FaSnapchatSquare /> <span>SnapChat</span></NavDropdown.Item>
                                        <NavDropdown.Item href='#'><FaAmazon /> <span>Amazon</span></NavDropdown.Item>
                                        <NavDropdown.Item href='#'><BsCashCoin /> <span>CashApp</span></NavDropdown.Item>
                                        <NavDropdown.Item href='#'><BsPaypal /> <span>PayPal</span></NavDropdown.Item>
                                        <NavDropdown.Item href='#'><SiVenmo /> <span>Venmo</span></NavDropdown.Item>
                                        <NavDropdown.Item href='#'><BsPinterest /> <span>Pinterest</span></NavDropdown.Item>
                                        <NavDropdown.Item href='#'><BsWallet /> <span>MetaMask</span></NavDropdown.Item>
                                    </NavDropdown>
                                </div>
                            </div>
                        </div>
                        <div className="post__carousel">
                            {t.content_images.length === 1 &&
                                <div>
                                    {t.content_images.map((image, i) => (
                                        <div className='image__wrapper'>
                                            <div className="carousel__image" key={i} style={getUrlExtension(image) === "mp4" ? videoStyle : {}} >
                                                {getUrlExtension(image) === "mp4" &&
                                                    <video
                                                        src={image}
                                                        onClick={() => setShowModal(true)}
                                                        ref={ref}
                                                        loop={true}
                                                        autoPlay
                                                        muted
                                                    ></video>}
                                                {getUrlExtension(image) === "jpg" &&
                                                    <LazyLoadImage
                                                        effect='blur'
                                                        src={image}
                                                        onClick={() => setShowModal(true)}
                                                        alt="" />}
                                                {(getUrlExtension(image) === "mp4" && !playing) ? <AiFillPlayCircle className='playPause__icons' /> : null}

                                            </div>
                                        </div>
                                    ))}
                                </div>
                            }
                            {t.content_images.length > 1 &&
                                <div>
                                    <Carousel
                                        showThumbs={false}
                                        swipeable={true}
                                        emulateTouch={true}
                                        showStatus={false}
                                        showArrows={true}
                                        centerMode={true}
                                        selectedItem={1}
                                        infiniteLoop={true}
                                        onClickItem={() => setShowModal(true)}
                                        preventMovementUntilSwipeScrollTolerance={true}
                                    >
                                        {t.content_images.map((image, i) => (
                                            <div className="carousel__image" key={i} style={getUrlExtension(image) === "mp4" ? videoStyle : {}} >
                                                {getUrlExtension(image) === "mp4" &&
                                                    <video
                                                        src={image}
                                                        onClick={() => setShowModal(true)}
                                                        ref={ref}
                                                        loop={true}
                                                        autoPlay
                                                        muted
                                                    ></video>}
                                                {getUrlExtension(image) === "jpg" &&
                                                    <LazyLoadImage
                                                        effect='blur'
                                                        src={image}
                                                        onClick={() => setShowModal(true)}
                                                        alt="" />}
                                                {(getUrlExtension(image) === "mp4" && !playing) ? <AiFillPlayCircle className='playPause__icons' /> : null}
                                            </div>
                                        ))}
                                    </Carousel>
                                </div>
                            }
                        </div>
                        <div className="post__bottom_engagement">
                            <div>
                                <div>{t.comments.length} <span>Comments</span></div>
                                <div>{t.num_likes}</div>
                            </div>
                            <div>
                                <div className="comment__count">
                                    <MdAddComment />
                                    <button onClick={handleComment} className='add__comment'>Add Comment</button>
                                </div>
                                <div className='like__block'>
                                    {likeLoading ? <Spinner animation='border' />
                                        :
                                        isLiked ? <div className="like__count">
                                            <AiFillLike />
                                            <button onClick={handleDisLike} className='like'>Like</button>
                                        </div>
                                            :
                                            <div className="dislike__count">
                                                <AiOutlineLike />
                                                <button onClick={handleLike} className='dislike'>Like</button>
                                            </div>
                                    }

                                </div>
                            </div>
                            {showComment && <>
                                <div className='comment__box'>
                                    <img src={Model} alt='icon' className='rounded__image' />
                                    <form onSubmit={handleAddComment}>
                                        <input type="text" placeholder='Write a comment...' onChange={(e) => setComment(e.target.value)} value={comment} required />
                                        {loadingComment ? <Spinner animation='border' /> : <button className='send_button'><BiSend /></button>}
                                    </form>
                                </div>
                                <div className='comment__contents'>
                                    {loadingDelComment && <p>Comment is deleting..</p>}
                                    {t.comments.map((comment_text, index) => (
                                        <div className='comment__inner' key={index}>
                                            <div className='comment__content' >
                                                <img src={Model} alt='icon' className='rounded__image__small' />
                                                <div className='comment__info'>
                                                    <p className='user__name'>{comment_text.name === null ? "Anonymous" : comment_text.name}</p>
                                                    <p>{comment_text.text}</p>
                                                </div>
                                            </div>
                                            <div className='comment__delete'>
                                                <button onClick={() => handleDelComment(comment_text.id)} className="comment__del__button"><MdDelete /></button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>}
                        </div>
                    </div>
                    {showModal &&
                        <Modal showModal={showModal} setShowModal={setShowModal} setPlaying={setPlaying} >
                            <div className="post__carousel">
                                {t.content_images.length > 1 ?
                                    <Carousel
                                        showThumbs={false}
                                        swipeable={true}
                                        emulateTouch={true}
                                        showStatus={false}
                                        showArrows={true}
                                        centerMode={true}
                                        selectedItem={1}
                                        infiniteLoop={true}
                                        preventMovementUntilSwipeScrollTolerance={true}
                                    >
                                        {t.content_images.map((image, i) => (
                                            <div className="carousel__image" key={i} style={getUrlExtension(image) === "mp4" ? videoStyle : {}} >
                                                {getUrlExtension(image) === "mp4" && <video onClick={handleVideoPress_zoom} ref={ref} src={image} autoPlay={false} loop={true} ></video>}
                                                {getUrlExtension(image) === "jpg" && <LazyLoadImage effect='blur' src={image} alt="" />}
                                                {(getUrlExtension(image) === "mp4" && !playing) ? <AiFillPlayCircle className='playPause__icons' /> : null}
                                            </div>
                                        ))}
                                    </Carousel> :
                                    <div className="carousel__image" style={getUrlExtension(t.content_images[0]) === "mp4" ? videoStyle : {}}  >
                                        {getUrlExtension(t.content_images[0]) === "mp4" && <video onClick={handleVideoPress_zoom} ref={ref} src={t.content_images[0]} autoPlay={true} loop={true} ></video>}
                                        {getUrlExtension(t.content_images[0]) === "jpg" && <LazyLoadImage effect='blur' src={t.content_images[0]} alt="" />}
                                        {(getUrlExtension(t.content_images[0]) === "mp4" && !playing) ? <AiFillPlayCircle className='playPause__icons' /> : null}
                                    </div>
                                }
                            </div>

                        </Modal>
                    }
                </>
                :
                null}
        </>

    )
};


export default Search;
