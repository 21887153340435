// If you don't want to use TypeScript you can delete this file!
import * as React from "react";
import logo from "../images/LogoFinal.png";
import "../styles/index.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link, navigate } from "gatsby";


const IndexPage = (location) => {
  return (
    <div className="welcomeContainer">
      <div className="welcome">
        <h6>Welcome</h6>
        <img src={logo} alt="logo"></img>
        <Link to="/home">
          <button
          // onClick={() =>
          //   window.location.replace(location.location.origin + "/home")
          // }
          >
            Continue
          </button>
        </Link>
      </div>
    </div>
  );
};

export default IndexPage;
