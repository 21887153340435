// If you don't want to use TypeScript you can delete this file!
import React, { useEffect, useState } from "react";
import logo from "../images/LogoFinal.png";
import "../styles/index.scss";
import { Link, navigate } from "gatsby";
import { Spinner } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';


const IndexPage = (location) => {
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("ageVerified") === "true") {
      if (localStorage.getItem("token")) {
        navigate("/home");
      } else {
        navigate("/browse");
      }
    } else {
      setPageLoading(false);
    }

    let user = JSON.parse(localStorage.getItem("user"));
    if (!user || !user.sessionID) {
      const id = uuid();
      if (user) {
        user["sessionID"] = id;
      } else {
        user = { sessionID: id }
      }

      localStorage.setItem("user", JSON.stringify(user));

    }
  }, [])

  return (
    <React.Fragment>
      {pageLoading
        ? <div className="index__page__loader">
          <Spinner animation="border" />
        </div>
        : <div className="welcomeContainer">
          <div className="welcome">
            <h6>Welcome</h6>
            <img src={logo} alt="logo"></img>
            <button
              onClick={() => {
                localStorage.setItem("ageVerified", "true");
                const date = new Date()
                localStorage.setItem("verifiedTime", date.toString());
                if (localStorage.getItem("token")) {
                  navigate("/home");
                } else {
                  navigate("/browse");
                }
              }
              }
            >
              I'm over 18 <br /> <span>I'm over the age of consent where I live</span>
            </button>
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default IndexPage;
