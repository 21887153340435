// If you don't want to use TypeScript you can delete this file!
import React, { useEffect, useState } from "react";
import logo from "../images/LogoFinal.png";
import "../styles/signup.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link, navigate } from "gatsby";
import axios from "axios";
import 'react-phone-number-input/style.css';
import PhoneInput from "react-phone-number-input";

const SignUpPage = ({ location }) => {
  const [username, setUsername] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const userStored = JSON.parse(localStorage.getItem("user"));
      let user = {
        sessionID: userStored.sessionID,
        phone: inputValue
      }
      await axios.post("https://api.opzoom.com/access4/test/send", user).then(res => {
        localStorage.setItem('user', JSON.stringify(user));
        console.log(res.data);
        navigate('/otp');
        setLoading(false);
      }).catch(err => {
        console.log(err);
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (localStorage.getItem("ageVerified") !== "true") {
      return navigate("/checkAge", {
        state: { redirectPage: location.pathname }
      })
    }

    const time = localStorage.getItem("verifiedTime");
    if (!time) {
      return navigate("/checkAge", {
        state: { redirectPage: location.pathname }
      });
    }
    const timeObject = Date.parse(time);
    const now = Date.now();
    const diffMs = now - timeObject
    const diffMin = Math.round(((diffMs % 86400000) % 3600000) / 60000)
    if (diffMin > 30) {
      localStorage.removeItem("ageVerified");
      localStorage.removeItem("verifiedTime");
      return navigate("/checkAge", {
        state: { redirectPage: location.pathname }
      });
    }
  });

  return (
    <div className="signUp__Container">
      <div className="signUp__Wrapper">
        <div className="signUp__image"><img src={logo} alt="logo" className="logo"></img></div>
        <h4>Log In/Sign Up</h4>
        <p>To get all access.</p>
        <form onSubmit={handleSubmit}>
          <div className="signUp__input__group">
            <label>Phone</label>
            <div className="input__group__phone">
              <PhoneInput
                placeholder="Enter Phone Number"
                value={inputValue}
                onChange={setInputValue}
                defaultCountry="US"
                required
              />
            </div>
          </div>

          {loading ? <button className="signUp__button" disabled={true}
          // onClick={() =>
          //   window.location.replace(location.location.origin + "/home")
          // }
          >
            Please wait..
          </button>
            :
            <button className="signUp__button"
            // onClick={() =>
            //   window.location.replace(location.location.origin + "/home")
            // }
            >
              Send Code
            </button>
          }



        </form>
      </div>
    </div>
  );
};

export default SignUpPage;
