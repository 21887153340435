import React, { useEffect, useState } from 'react';
import "../styles/index.scss";
import { navigate } from "gatsby";

const NotFoundPage = (location) => {
  const [isAgeVerified, setIsAgeVerified] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("ageVerified") === "true") {
      setIsAgeVerified(true);
    }
    setLoading(false);
  }, [])

  return (
    <div className="welcomeContainer">
      <div className="welcome" style={{ marginTop: '50px' }}>
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <button
          onClick={() => {
            navigate("/browse");
            if (!isAgeVerified)
              localStorage.setItem("ageVerified", "true");
          }
          }
          disabled={loading}
        >
          {loading ? "Please wait..." : (isAgeVerified ? "Browse" : "I'm over 18")}
        </button>
      </div>
    </div>
  )
}

export default NotFoundPage
