import React, { useEffect, useRef, useState } from "react";
import manyvids from "../../images/many vids.png";
import cfLogo from '../../images/centerfold.png';
import spLogo from '../../images/Sextpantherlogo.png';
import instagramLogo from '../../images/instagram.png';
import onlyfans from '../../images/only fans.png';
import pw from '../../images/personal.png';
import amazon from '../../images/amazon wishlist.png';
import ph from '../../images/pornhub.png';
import reddit from '../../images/reddit.png';
import twitch from '../../images/twitch.png';
import twitter from '../../images/twitter.png';
import SoSpoilt from "../../images/SoSpoilt.png"
import Snapchat from "../../images/snapchat.png"
import UMD from "../../images/UMD.png";
import { Button, Modal } from "react-bootstrap";
import "./ExternLinks.scss";


const LOGOS = {
    Instagram: instagramLogo,
    Twitter: twitter,
    Centerfold: cfLogo,
    SextPanther: spLogo,
    OnlyFans: onlyfans,
    PersonalWebsite: pw,
    AmazonWishlist: amazon,
    Reddit: reddit,
    Twitch: twitch,
    Pornhub: ph,
    ManyVids: manyvids,
    SoSpoilt: SoSpoilt,
    UMD,
    Snapchat
}

const ExternalLinks = ({ links = [] }) => {
    const [showMoreLinks, setShowMoreLinks] = useState(false);
    const [iconLinks, setIconLinks] = useState([]);
    const [noOfInlineIcons, setNoOfInlineIcons] = useState(8);

    useEffect(() => {
        let iconLinks = []
        console.log("Links: ", links)
        iconLinks = links.map(l => {
            const name = Object.keys(l).pop();
            const url = l[name];
            return {
                name,
                url,
                logo: LOGOS[name]
            }
        })
        setIconLinks(iconLinks);
    }, [links]);

    useEffect(() => {
        const handler = () => {
            if (window.innerWidth < 800) {
                setNoOfInlineIcons(Math.floor((window.innerWidth - 170) / 70));
            } else {
                setNoOfInlineIcons(8);
            }
        }
        window.addEventListener('resize', handler);
        handler()
    }, []);

    return (
        <>
            <div className="extern-links">
                <div className="inline">
                    {iconLinks.slice(0, noOfInlineIcons).map((link, index) => (
                        <a href={link.url} target="_blank" key={index}>
                            <img src={link.logo} alt={link.name} />
                        </a>
                    ))}
                </div>
                {(noOfInlineIcons < iconLinks.length) && <Button
                    className="extern-links__button"
                    variant="outline-danger"
                    onClick={() => setShowMoreLinks(true)}>More</Button>}
            </div>
            <Modal
                show={showMoreLinks}
                onHide={() => setShowMoreLinks(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className="extern-links-modal">
                        {iconLinks.map((link, index) => (
                            <div className="link">
                                <a href={link.url} target="_blank">
                                    <img src={link.logo} alt="" />
                                    <span>{link.name}</span>
                                </a>
                            </div>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="extern-links__button"
                        variant="outline-danger"
                        onClick={() => setShowMoreLinks(false)}
                    >
                        Back
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}

export default ExternalLinks;
