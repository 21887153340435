import React, { useCallback, useEffect, useRef } from 'react';
import CloseIcon from '../../images/closeIcon.svg';
import "./OtpModal.scss"


const OtpModal = ({ showModal, setShowModal, children }) => {
    const modalRef = useRef()
    const keyPress = useCallback(
        e => {
            if (e.key === 'Escape' && showModal) {
                setShowModal(false);
                console.log('I pressed');
            }
        },
        [setShowModal, showModal]
    );

    const closeModal = e => {
        if (modalRef.current === e.target) {
            setShowModal(false);
        }
    };

    useEffect(
        () => {
            document.addEventListener('keydown', keyPress);
            return () => document.removeEventListener('keydown', keyPress);
        },
        [keyPress]
    );

    return (
        <div className="modal__wrapper">
            <div className="modal__backdrop" ref={modalRef} onClick={closeModal} >
                <div className="modal__image__wrapper">
                    <div className="modal__content">
                        {children}
                    </div>
                    <div className="modal__closeIcon" onClick={() => {
                        setShowModal(false)
                    }} >
                        <img src={CloseIcon} alt="close" />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default OtpModal;
