import React, { useEffect, useState } from 'react';
import "../styles/browse.scss";
import Sidebar from "../components/sidebar"
import Layout from '../components/layout';
import { BsFillHeartFill, BsHeart } from 'react-icons/bs';
import axios from 'axios';
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'gatsby';


const Browse = ({ location }) => {
    const [data, setData] = useState(null);

    const fetchUserData = () => {
        if (!localStorage.getItem('token')) {
            axios.post('https://api.opzoom.com/access4/test/getBrowseScreen', {
                phone: "180000000"
            }).then(res => {
                setData(res.data);
            }).catch(err => {
                console.log(err);
            })
        } else {
            const { token, settings } = JSON.parse(localStorage.getItem('token'));
            axios.post('https://api.opzoom.com/access4/test/getBrowseScreen', {
                phone: settings.phone
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            }).then(res => {
                setData(res.data);
            }).catch(err => {
                console.log(err);
            })
        }
    }

    useEffect(() => {
        fetchUserData()
    }, [])

    return (
        <Layout location={location}>
            <div className='home__container  container' >
                <div className="wrapper">
                    <Sidebar />
                    <div className="browse__wrapper__main">
                        <div className='browse__block'>
                            {data?.map((item, index) => (
                                <div className="browse__avatar" key={index}>
                                    <div className='avatar'>
                                        <Link to={`/profile/${item?.handle}`} state={{ handle: item?.handle }} ><img src={item?.profile_image_url} alt="" /></Link>
                                        {item?.i_follow === 0 ? <div className='heart__top'>
                                            <BsHeart />
                                        </div>
                                            :
                                            <div className='heart__top__red'>
                                                <BsFillHeartFill />
                                            </div>}
                                    </div>
                                    <div className='avatar__info'>
                                        <h4>{item?.name}</h4>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='ads__banner_middle'>
                            <h4>Ads Banner</h4>
                            <div className='ads__carousel__wrapper'>
                                <div className="ads__carousel">
                                    <Carousel
                                        showThumbs={false}
                                        swipeable={true}
                                        emulateTouch={true}
                                        showStatus={false}
                                        showArrows={true}
                                        preventMovementUntilSwipeScrollTolerance={true}
                                    >
                                        <img className='carousel__image' src="https://access4-media.opzoom.com/ads/001.jpg" alt='ads__1' />
                                        <img className='carousel__image' src="https://access4-media.opzoom.com/ads/002.jpg" alt='ads__2' />
                                    </Carousel>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Layout>
    )
};

export default Browse;

